* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.loader {
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

html, body, .root, .Main, .PagesSwitcher {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
}

.OrderPage {
  min-height: 100%;
  max-height: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.middle {
  background: #f0f0f0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.bottom {
  text-align: center;
  background-color: #222;
  padding: 16px;
}

.mini-button {
  padding: 2px 4px !important;
  line-height: 18px !important;
}

.tf-form-label, .tf-form-labeled-number .label {
  min-width: 120px;
  display: inline-block;
}

.tf-form-input, .tf-form-labeled-number .input {
  width: auto;
}

.radio label {
  display: block;
}

.title {
  color: #f8f8f8;
  background: linear-gradient(#424242 0%, #313131 100%);
  border-bottom: 4px solid #ff7b0d;
  padding: 12px 12px 16px 16px;
  font-size: 18px;
}

.title .order-id {
  text-align: right;
  color: #bbb;
  margin-bottom: 2px;
  font-size: 12px;
}

.footer-phone {
  color: #fff;
  font-size: 16px;
}

.footer-hours {
  color: #aaa;
  font-size: 12px;
}

@media screen and (max-width: 700px) {
  .bottom {
    padding: 8px;
  }

  .footer-phone {
    font-size: 14px;
  }

  .footer-hours {
    font-size: 10px;
  }

  .title {
    padding: 6px 8px;
    font-size: 14px;
  }

  .title .order-id {
    margin-bottom: 2px;
    font-size: 10px;
  }
}

.filter-switch {
  cursor: pointer;
  border-radius: 16px;
  margin-left: 4px;
  padding: 4px 8px;
}

.filter-switch:hover {
  background-color: #c8f2ff;
}

/*# sourceMappingURL=index.c16d5fdc.css.map */

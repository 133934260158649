* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 100%;
}

html,
body,
.root,
.Main,
.PagesSwitcher {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    margin: 0;
}

.OrderPage {
    min-height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}


.middle {
    overflow: scroll;
    overflow-x: hidden;
    background: #f0f0f0;
}

.bottom {
    padding: 16px;
    background-color: #222;
    text-align: center;
}

.mini-button {
    padding: 2px 4px !important;
    line-height: 18px !important;
}

.tf-form-label,
.tf-form-labeled-number .label {
    min-width: 120px;
    display: inline-block;
}

.tf-form-input,
.tf-form-labeled-number .input {
    width: auto;
}

.radio label {
    display: block;
}

.title {
    padding: 12px;
    padding-left: 16px;
    padding-bottom: 16px;
    font-size: 18px;
    background: #0e6dcd;
    color: #f8f8f8;
    background: linear-gradient(to bottom,  #424242 0%,#313131 100%);
    border-bottom: 4px solid #ff7b0d;
}

.title .order-id {
    text-align: right;
    font-size: 12px;
    color: #bbb;
    margin-bottom: 2px;
}

.footer-phone {
    font-size: 16px;
    color: #fff;
}

.footer-hours {
    font-size: 12px;
    color: #aaa;
}


@media screen and (max-width: 700px) {
 
    .bottom {
        padding: 8px;
    }

    .footer-phone {
        font-size: 14px;
    }

    .footer-hours {
        font-size: 10px;
    }

    .title {
        padding: 6px 8px;
        font-size: 14px;
    }

    .title .order-id {
        font-size: 10px;
        margin-bottom: 2px;
    }
}

.filter-switch {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 16px;
    margin-left: 4px;
}

.filter-switch:hover {
    background-color: #c8f2ff;
}
